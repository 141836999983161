import {
  Bookmark,
  Category,
  Game,
  Image,
  Paper,
  Setting,
  Star,
  Ticket,
  TwoUsers,
  Wallet,
} from "react-iconly";

const pages = [
  {
    header: "UTAMA",
  },
  {
    id: "statistik",
    title: "Statistik",
    icon: <Category set="curved" className="remix-icon" />,
    navLink: "/panel/statistic",
  },
  {
    id: "member",
    title: "Member",
    icon: <TwoUsers set="curved" className="remix-icon" />,
    navLink: "/panel/member/data",
  },
  {
    id: "transaction",
    title: "Transaksi",
    icon: <Paper set="curved" className="remix-icon" />,
    navLink: "/panel/transaction/data",
  },
  // {
  //   id: "saldo-point",
  //   title: "Saldo Point",
  //   icon: <Wallet set="curved" className="remix-icon" />,
  //   navLink: "/panel/saldo-point/data",
  // },
  {
    id: "flash-sale",
    title: "Flash Sale",
    icon: <Star set="curved" className="remix-icon" />,
    navLink: "/panel/flash-sale/data",
  },
  {
    id: "promo",
    title: "Kode Promo",
    icon: <Ticket set="curved" className="remix-icon" />,
    navLink: "/panel/promo/data",
  },
  {
    id: "banner",
    title: "Banner",
    icon: <Image set="curved" className="remix-icon" />,
    navLink: "/panel/banner/data",
  },
  {
    id: "product",
    title: "Produk",
    icon: <Game set="curved" className="remix-icon" />,
    children: [
      {
        id: "product-vcg",
        title: "Produk Supply",
        navLink: "/panel/product-vcg/data",
      },
      {
        id: "category-label-data",
        title: "Produk Custom",
        navLink: "/panel/product-custom/data",
      },
    ],
  },
  {
    id: "category",
    title: "Kategori",
    icon: <Bookmark set="curved" className="remix-icon" />,
    children: [
      {
        id: "category-group",
        title: "Kategori",
        navLink: "/panel/category/data",
      },
      {
        id: "category-label-data",
        title: "Label Kategori",
        navLink: "/panel/category-label/data",
      },
    ],
  },

  {
    header: "LAINNYA",
  },
  {
    id: "settings",
    title: "Pengaturan",
    icon: <Setting set="curved" className="remix-icon" />,
    children: [
      {
        id: "web-panel",
        title: "Web Setting",
        navLink: "/panel/setting/web-panel",
      },
      {
        id: "member-tier",
        title: "Member Tier",
        navLink: "/panel/setting/member-tier/data",
      },
      {
        id: "payment-method",
        title: "Metode Pembayaran",
        navLink: "/panel/setting/payment-method/data",
      },
      {
        id: "socmed",
        title: "Kontak & Sosial Media",
        navLink: "/panel/setting/social-media",
      },
    ],
  },
];

export default pages;
